import React from 'react';
import { getFieldData } from 'common/utils';
import Footer from './Footer_table';
import Branding from './Branding';
import SocialBanners from './SocialBanners_table';
import CallToAction from './CallToAction_table';
import CallToActionBanner from './CallToActionBanner_table';

function AddOns({
  signature = {},
  design = {},
  hideBranding = {},
  showAwsIcons = {},
  order = ['CallToAction', 'CallToActionBanner', 'SocialBanners', 'Footer'], // Default order
  styles = {},
  backgroundColor,
  alignment,
}) {
  const { fields } = signature || {};

  const components = {
    CallToAction: () =>
      getFieldData(fields, 'ctaUrl') &&
      getFieldData(fields, 'ctaTitle') && (
        <CallToAction
          signature={signature}
          showAwsIcons={showAwsIcons}
          design={design}
          data={{
            ctaUrl: getFieldData(fields, 'ctaUrl'),
            ctaTitle: getFieldData(fields, 'ctaTitle'),
          }}
          style={{
            fontFamily: 'inherit',
            ...styles,
          }}
        />
      ),
    CallToActionBanner: () =>
      getFieldData(fields, 'ctaBannerImg') &&
      getFieldData(fields, 'ctaBannerUrl') && (
        <CallToActionBanner
          data={{
            ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
            ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
          }}
          design={design}
          signature={signature}
          style={{
            fontFamily: 'inherit',
            ...styles,
          }}
        />
      ),
    SocialBanners: () =>
      !!getFieldData(fields, 'socialBanners')?.value?.length && (
        <SocialBanners
          data={getFieldData(fields, 'socialBanners')}
          signature={signature}
          design={design}
          style={{
            fontFamily: 'inherit',
            ...styles,
          }}
          alignment={alignment}
        />
      ),
    Footer: () =>
      getFieldData(fields, 'footer') && (
        <Footer
          data={getFieldData(fields, 'footer')}
          signature={signature}
          design={design}
          style={{
            fontFamily: 'inherit',
            ...styles,
          }}
        />
      ),
  };
  return (
    // <>
    //   {getFieldData(fields, 'ctaUrl') && getFieldData(fields, 'ctaTitle') && (
    //     <CallToAction
    //       signature={signature}
    //       showAwsIcons={showAwsIcons}
    //       design={design}
    //       data={{
    //         ctaUrl: getFieldData(fields, 'ctaUrl'),
    //         ctaTitle: getFieldData(fields, 'ctaTitle'),
    //       }}
    //     />
    //   )}
    //   {getFieldData(fields, 'ctaBannerImg') &&
    //     getFieldData(fields, 'ctaBannerUrl') && (
    //       <CallToActionBanner
    //         data={{
    //           ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
    //           ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
    //         }}
    //         design={design}
    //         signature={signature}
    //       />
    //     )}
    //   {!!getFieldData(fields, 'socialBanners')?.value?.length && (
    //     <SocialBanners
    //       data={getFieldData(fields, 'socialBanners')}
    //       signature={signature}
    //       design={design}
    //     />
    //   )}
    //   {getFieldData(fields, 'footer') && (
    //     <Footer
    //       data={getFieldData(fields, 'footer')}
    //       signature={signature}
    //       design={design}
    //     />
    //   )}

    //   <Branding hideBranding={hideBranding} />
    // </>

    <>
      {order.map((component) => {
        const ComponentToRender = components[component];
        return ComponentToRender ? ComponentToRender() : null;
      })}
      <Branding hideBranding={hideBranding} />
    </>
  );
}

export default AddOns;
