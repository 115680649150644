/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS, TEXT_IDS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

function TemplateStyleTwentyFive({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);

  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );
  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'companyLogo');

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  const separatorWidth = get(design, 'styles.separator.style.width', 1);
  const separatorColor = get(
    design,
    'styles.separator.style.color',
    design?.primaryBrandColor,
  );
  const separatorType = get(design, 'styles.separator.style.type', 'solid');
  const contentWidth = get(design, 'styles.content.style.width', 600);
  return (
    <>
      <table
        width={`${contentWidth}px`}
        cellPadding="0"
        cellSpacing="0"
        border="0"
        style={{
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontWeight: 700,
                fontFamily: 'inherit',
              },
            })}
          </tr>
        )}
        <tr>
          <td aria-label="main-content">
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
              }}
            >
              <tr>
                <td>
                  <table
                    cellPadding="0px"
                    cellSpacing="0"
                    border="0"
                    ref={mainContentRef}
                  >
                    <tr>
                      <td>
                        {(getFieldData(fields, 'headshotUrl') ||
                          getFieldData(fields, 'companyLogo')) && (
                          <table
                            cellPadding="0"
                            cellSpacing="0"
                            style={{
                              paddingRight: '20px',
                            }}
                          >
                            {getFieldData(fields, 'headshotUrl') && (
                              <tr align="center">
                                {HeadshotImage({
                                  data: getFieldData(fields, 'headshotUrl'),
                                  design,
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'companyLogo') && (
                              <tr align="center">
                                {CompanyLogo({
                                  data: getFieldData(fields, 'companyLogo'),
                                  design,
                                })}
                              </tr>
                            )}
                          </table>
                        )}
                      </td>

                      {!allUrlsAreNull && (
                        <td
                          className={TEXT_IDS.SEPARATOR}
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 0,
                            lineHeight: '100%',
                            paddingLeft: '20px',
                            borderLeft: `${separatorWidth}px ${separatorType} ${separatorColor}`,
                          }}
                        >
                          <table cellPadding="0px" cellSpacing="0" border="0">
                            <tr>
                              <td>
                                <table
                                  cellPadding="0px"
                                  cellSpacing="0"
                                  border="0"
                                  style={{
                                    paddingBottom: '8px',
                                  }}
                                >
                                  <tr>
                                    {getFieldData(fields, 'name') &&
                                      Name({
                                        signature,
                                        data: getFieldData(fields, 'name'),
                                        style: {
                                          fontWeight: 300,
                                          fontFamily: 'inherit',
                                          color: '#000000',
                                        },
                                      })}
                                  </tr>
                                  <tr valign="center">
                                    {getFieldData(fields, 'position') &&
                                      Position({
                                        signature,
                                        data: getFieldData(fields, 'position'),
                                        style: {
                                          fontFamily: 'inherit',
                                        },
                                      })}
                                  </tr>
                                  <tr>
                                    {getFieldData(fields, 'department') &&
                                      Department({
                                        signature,
                                        data: getFieldData(
                                          fields,
                                          'department',
                                        ),
                                        style: {
                                          fontFamily: 'inherit',
                                        },
                                      })}
                                  </tr>
                                  <tr>
                                    {getFieldData(fields, 'companyName') &&
                                      CompanyName({
                                        signature,
                                        data: getFieldData(
                                          fields,
                                          'companyName',
                                        ),
                                        style: {
                                          fontFamily: 'inherit',
                                        },
                                      })}
                                  </tr>
                                </table>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  paddingRight: '14px',
                                }}
                              >
                                <table
                                  cellPadding="0px"
                                  cellSpacing="0"
                                  border="0"
                                >
                                  <td>
                                    <tr>
                                      {getFieldData(fields, 'phone') &&
                                        Phone({
                                          signature,
                                          showAwsIcons,
                                          design,
                                          data: getFieldData(fields, 'phone'),
                                        })}
                                    </tr>
                                    {getFieldData(fields, 'companyPhone') && (
                                      <tr>
                                        {CompanyPhone({
                                          signature,
                                          showAwsIcons,
                                          design,
                                          data: getFieldData(
                                            fields,
                                            'companyPhone',
                                          ),
                                          style: {
                                            paddingRight: '8px',
                                          },
                                        })}
                                      </tr>
                                    )}
                                    {getFieldData(fields, 'email') && (
                                      <tr>
                                        {CompanyEmail({
                                          signature,
                                          showAwsIcons,
                                          design,
                                          data: getFieldData(fields, 'email'),
                                        })}
                                      </tr>
                                    )}
                                    {getFieldData(fields, 'companyEmail') && (
                                      <tr>
                                        {Email({
                                          signature,
                                          showAwsIcons,
                                          design,
                                          data: getFieldData(
                                            fields,
                                            'companyEmail',
                                          ),
                                        })}
                                      </tr>
                                    )}
                                    {getFieldData(fields, 'website') && (
                                      <tr>
                                        {Website({
                                          signature,
                                          showAwsIcons,
                                          design,
                                          data: getFieldData(fields, 'website'),
                                        })}
                                      </tr>
                                    )}
                                  </td>
                                </table>
                              </td>

                              <td
                                style={{
                                  paddingTop: '16px',
                                }}
                              >
                                <table
                                  cellPadding="0px"
                                  cellSpacing="0"
                                  border="0"
                                >
                                  {getFieldData(fields, 'companyAddress') && (
                                    <tr>
                                      {CompanyAddress({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(
                                          fields,
                                          'companyAddress',
                                        ),
                                        breakAtCommas: true,
                                      })}
                                    </tr>
                                  )}
                                </table>
                              </td>
                            </tr>
                            {!!socialFields?.length && (
                              <tr>
                                {SocialLinks({
                                  signature,
                                  showAwsIcons,
                                  data: socialFields,
                                  design,
                                })}
                              </tr>
                            )}
                          </table>
                        </td>
                      )}
                    </tr>
                  </table>
                </td>
              </tr>
              {/* <tr>
          {getFieldData(fields, 'ctaUrl') &&
            getFieldData(fields, 'ctaTitle') &&
            CallToAction({
              signature,
              showAwsIcons,
              design,
              data: {
                ctaUrl: getFieldData(fields, 'ctaUrl'),
                ctaTitle: getFieldData(fields, 'ctaTitle'),
              },
            })}
        </tr>
        <tr>
          {getFieldData(fields, 'ctaBannerImg') &&
            getFieldData(fields, 'ctaBannerUrl') &&
            CallToActionBanner({
              data: {
                ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
              },
              design,
              style: { textAlign: 'left' },
            })}
        </tr>
        {!!getFieldData(fields, 'socialBanners')?.value?.length && (
          <tr>
            {SocialBanners({
              data: getFieldData(fields, 'socialBanners'),
              design,
              style: {
                textAlign: 'left',
              },
            })}
          </tr>
        )}
        {getFieldData(fields, 'footer') && (
          <tr>
            {Footer({
              data: getFieldData(fields, 'footer'),
              design,
            })}
          </tr>
        )} */}
            </table>
          </td>
        </tr>
        {/* <tr>
      <Branding hideBranding={hideBranding} />
    </tr> */}
      </table>
      <AddOns
        signature={signature}
        design={design}
        hideBranding={hideBranding}
        showAwsIcons={showAwsIcons}
      />
    </>
  );
}

export default TemplateStyleTwentyFive;
