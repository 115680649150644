import { useGetGroupById } from 'hooks/group';
import {
  ArrowLeftRight,
  ChevronLeft,
  ArrowLeft,
  LoaderCircle,
} from 'lucide-react';
import { useGroupId, useWorkspaceId } from 'hooks/workspace';
import { Button } from 'components/ui/button';
import {
  useFetchWorkspaceTemplate,
  useFetchWorkspaceTemplates,
} from 'hooks/workspace-template';
import { get } from 'lodash';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import LoaderComponent from 'components/LoaderComponent';
import { useRouter } from 'hooks/router';
import { useQueuePolling } from 'hooks/queue';
import { useGetWorkspaceTeammateByWorkspaceId } from 'hooks/workspace-teammate';
import WorkspaceTemplatePreview from './components/WorkspaceTemplatePreview';
import TeammateList from './components/TeammateList';
import SwitchTemplateDialog from './components/SwitchTemplateDialog';

const GroupEdit = () => {
  const { groupId } = useGroupId();
  const { workspaceId } = useWorkspaceId();
  const [getGroupById] = useGetGroupById();
  const [fetchWorkspaceTemplate] = useFetchWorkspaceTemplate();
  const [fetchWorkspaceTemplates] = useFetchWorkspaceTemplates();
  const switchTemplateDialogRef = useRef();

  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState(null);
  const [workspaceTemplate, setWorkspaceTemplate] = useState(null);
  const { navigate } = useRouter();
  const [getWorkspaceTeammateByWorkspaceId] =
    useGetWorkspaceTeammateByWorkspaceId();

  const fetchWorkspaceTeammates = useCallback(async () => {
    await getWorkspaceTeammateByWorkspaceId({
      variables: {
        where: {
          workspaceId,
        },
      },
    });
  }, [workspaceId, getWorkspaceTeammateByWorkspaceId]);

  const { queueStatus, queueLoading, pollQueueStatus } = useQueuePolling(
    'updateTeammateQueue',
    fetchWorkspaceTeammates,
  );

  const fetchWorkSpaceTemplate = async (id) => {
    // fetch workspace template here
    const res = await fetchWorkspaceTemplate({
      variables: {
        where: { workspaceTemplateId: id },
      },
    });
    const currentWorkspaceTemplate = get(
      res,
      'data.getWorkspaceTemplate.data',
      null,
    );
    setWorkspaceTemplate(currentWorkspaceTemplate);
  };
  const handleIconClick = () => {};
  const fetchGroup = async () => {
    setLoading(true);
    const res = await getGroupById({ variables: { id: groupId } });
    const groupRes = get(res, 'data.getGroupById.data', null);
    const templateId = get(groupRes, 'templateId', null);
    if (templateId) {
      await fetchWorkSpaceTemplate(templateId);
    }
    setGroup(groupRes);
    setLoading(false);
  };

  const handleBackClick = async () => {
    navigate(`/app/workspace/${workspaceId}/groups`);
  };

  useEffect(() => {
    if (groupId) {
      fetchGroup();
    }
  }, [groupId]);

  if (loading) {
    return (
      <div className="bg-primary-foreground  p-[24px]">
        <div className="bg-white-0 w-full h-[calc(100vh_-_120px)] overflow-auto relative flex items-center justify-center">
          <LoaderComponent />
        </div>
      </div>
    );
  }

  const handleSwitchTemplateOpen = async () => {
    const res = await fetchWorkspaceTemplates({
      variables: { data: { workspaceId } },
    });
    const data = get(res, 'data.getAllWorkspaceTemplate.data', []);
    switchTemplateDialogRef?.current?.onDataChange(data);
    switchTemplateDialogRef?.current?.open();
  };

  return (
    <>
      <SwitchTemplateDialog
        fetchWorkSpaceTemplate={fetchWorkSpaceTemplate}
        ref={switchTemplateDialogRef}
        onClose={pollQueueStatus}
        alreadySelectedTemplateId={workspaceTemplate}
      />
      <div className="bg-primary-foreground  p-[24px]">
        <div className="bg-white-0 w-full h-[calc(100vh_-_120px)] overflow-auto relative">
          <div className="flex justify-between bg-white shadow-lg rounded-lg w-full text-left items-center">
            <h1 className="flex items-center text-lg font-bold  px-6 mb-1 mt-4">
              <Button
                onClick={handleBackClick}
                variant="text"
                className="px-0 py-0 mr-2"
              >
                <ArrowLeft />
              </Button>
              {group?.name}
            </h1>
            {queueStatus.delayed > 0 && (
              <div className="flex justify-between px-[24px] py-[18px] pt-[20px] ms-center">
                <div className="flex items-center gap-4 bg-transparent p-0 border-none">
                  {/* Loader Animation */}
                  {queueLoading && (
                    <div className="flex items-center gap-2">
                      <LoaderCircle className="w-5 sm:w-6 h-5 sm:h-6 text-blue-500 animate-spin" />
                      <span className="text-xs sm:text-sm text-gray-600 font-medium">
                        Processing teammates for template change...
                      </span>
                    </div>
                  )}

                  {/* Queue Status Info */}
                  <div className="flex items-center gap-2 text-xs sm:text-sm font-medium text-gray-700">
                    ⏳ <span>Teammates Pending:</span>{' '}
                    <b className="text-yellow-600">{queueStatus.delayed}</b>
                  </div>
                </div>
              </div>
            )}
            <div className="px-6 mb-1 mt-4">
              <Button
                onClick={handleSwitchTemplateOpen}
                className="bg-white-0 hover:bg-primary-foreground text-gray-400 hover:text-gray-600 p-2 border border-solid border-gray-1 rounded-full"
              >
                <ArrowLeftRight className="h-6 w-6" />
              </Button>
            </div>
          </div>
          <div className="border-solid border-4 border-gray-200 rounded-lg p-2 m-4">
            <h2 className="text-lg font-bold mb-4 justify-center">
              Signature template
            </h2>
            <div className="flex items-center justify-center">
              <div className="bg-white shadow-lg rounded-lg p-6 max-w-2xl w-full items-center justify-center">
                <WorkspaceTemplatePreview
                  workspaceTemplate={workspaceTemplate}
                  openTemplateSwitcher={handleSwitchTemplateOpen}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="bg-white shadow-lg rounded-lg pl-6 max-w-md w-full text-left">
              <h2 className="text-lg font-bold mb-4">Teammate List</h2>
            </div>
            <div>
              <TeammateList
                group={group}
                workspaceTemplate={workspaceTemplate}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupEdit;
