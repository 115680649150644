import { useAppContext } from 'AppContext';
import { ChevronRight, Zap } from 'lucide-react';
import { useMutation } from '@apollo/client';
import { toast } from 'hooks/use-toast';
import { omit, replace } from 'lodash';
import AiHeadshotButton from 'components/AiHeadshotButton';
import React, { useEffect, useState } from 'react';
import { useGetSignature } from 'hooks/signature';
import { useGetHeadshot } from 'hooks/headshot';
import { fileUpload, getLiveUrl } from 'common/utils';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useForm } from 'react-hook-form';
import { useRouter } from 'hooks/router';
import { UPSERT_SIGNATURE } from 'modules/Signature/graphql/Mutations';
import { UPDATE_USER } from 'modules/Auth/graphql/Mutations';
import { HEAD_SHOT_SIGNED_URL } from 'modules/Headshot/graphql/Queries';
import {
  CREATE_HEADSHOT,
  PROCCESS_HEADSHOT_IMAGE,
} from 'modules/Headshot/graphql/Mutations';
import client from 'apollo';
import PreparingHeadshot from 'components/PreparingHeadshot';
import SideCoverHeadshot from 'assets/images/side-cover.png';
import { onboardingStatus } from 'common/constants';
import { Button } from 'components/ui/button';
import { useRedirect } from 'hooks/auth';
import { useWorkspaceId } from 'hooks/workspace';
import { useFeatureChecks, useGetBrandDetails } from 'hooks/brand';
import ImageUploadPreview from 'components/ImageUploadPreview';
import Cropper from 'components/Cropper';
import OnboardingSignaturePreview from 'components/OnboardingSignaturePreview';
import PrivateHeadshotEditor from './PrivateHeadshotEditor';

function PrivateHeadshot() {
  const {
    state: { signature, headshot },
    setSignature,
    setHeadshotUpdateStatus,
    setUserLocalData,
    setCurrentUser,
  } = useAppContext();

  const { logoUrl, name } = useGetBrandDetails();
  const { isAIHeadshotsAllowed } = useFeatureChecks();
  const form = useForm();
  const { redirectUser } = useRedirect();

  const { params, navigate } = useRouter();
  const { id: signatureId } = params;
  const { workspaceId } = useWorkspaceId();

  const [getSignature] = useGetSignature();
  const [getHeadshot] = useGetHeadshot();

  const [isUploadFailed, setIsUploadFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-undef

  const [setOnboard] = useMutation(UPSERT_SIGNATURE, {
    onCompleted() {},
    onError() {},
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    // update user onboard journey to in user data and local storage
    onCompleted(res) {
      setUserLocalData(res?.updateUser?.data);
      setCurrentUser(res?.updateUser?.data);
    },
    onError() {},
  });

  const [createHeadshot] = useMutation(CREATE_HEADSHOT, {
    onCompleted(res) {
      getHeadshot({
        variables: { where: { id: res?.createHeadshot?.data?.id } },
      });
      setSignature({
        ...signature,
        headshotId: res?.createHeadshot?.data?.id,
      });

      if (signatureId) {
        const { id: _notUsed, ...restSignature } = signature;
        setOnboard({
          variables: {
            data: {
              ...restSignature,
              headshotId: res?.createHeadshot?.data?.id,
            },
            where: {
              signatureId: signatureId || signature?.id || '',
              workspaceId, // signature id for MUTATION_headshot
            },
          },
        });
      }
      setHeadshotUpdateStatus(true);
    },
    onError() {
      setLoading(false);
    },
  });

  const [processHeadshot] = useMutation(PROCCESS_HEADSHOT_IMAGE, {
    onCompleted: (res) => {
      setLoading(true);
      if (res?.processHeadshotImage) {
        const outputImage = getLiveUrl(res?.processHeadshotImage?.key);

        createHeadshot({
          variables: {
            data: {
              image: outputImage || res?.processHeadshotImage?.key,
            },
          },
        });
      }
    },
    onError: () => {
      setLoading(false);
    },
  });

  const loadSignatureData = async () => {
    const res = await getSignature({
      variables: {
        where: { signatureId, workspaceId },
      }, // signature id for QUERY
    });
    const headshotId = res?.data?.signature?.headshotId;
    if (headshotId && res?.data?.signature?.headshot) {
      await getHeadshot({
        variables: {
          where: {
            id: headshotId,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (signatureId) {
      if (
        signatureId === 'undefined' ||
        signatureId === 'null' ||
        signatureId === null
      ) {
        redirectUser();
      }
      loadSignatureData();
    }
  }, [signatureId]);

  const handleImageUpload = async (file = null) => {
    const fileName = replace(file?.[0]?.name, new RegExp(' ', 'g'), '_');
    const res = await client?.query({
      query: HEAD_SHOT_SIGNED_URL,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          fileName,
        },
      },
    });

    const { signedUrl = '', key = '' } =
      await res?.data?.getHeadshotUploadSignedUrl;
    try {
      await fileUpload(signedUrl, file?.[0]);
      await processHeadshot({
        variables: {
          where: {
            key,
          },
        },
      });
    } catch (error) {
      setIsUploadFailed(true);
      // message?.error(error?.message);
      toast({
        closeicn: 'destructive',
        description: error?.message,
      });
    }
  };

  const handleFinish = async (values) => {
    const { uploadPicture } = values;
    setLoading(true);
    setIsUploadFailed(false);
    setSignature(omit(signature, 'workspaceId'));
    await handleImageUpload(uploadPicture);
    setLoading(false);
  };

  if (loading) {
    return <PreparingHeadshot />;
  }

  if (headshot?.id) {
    return <PrivateHeadshotEditor />;
  }

  const uploadPicture = form.watch('uploadPicture');

  return (
    <div className="min-h-screen h-full max-h-screen grid grid-cols-2 lg:grid-cols-1 overflow-auto">
      <div className="flex flex-col justify-center bg-primary-foreground p-[16px]  overflow-auto">
        <div className="w-full  max-w-[600px] mx-auto">
          <img
            src={logoUrl || '/logo.svg'}
            height={40}
            width="auto"
            alt="logo"
          />
          <h1
            level={1}
            className="text-primary font-primary  text-[32px] font-semibold leding-[36px] mt-[60px] mb-[60px]"
          >
            Professional headshot
          </h1>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleFinish)}
              form={form}
              initialValues={{ remember: true }}
            >
              <FormField
                control={form.control}
                name="uploadPicture"
                className="mb-[16px]"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Upload your picture</FormLabel>
                    <FormControl>
                      <>
                        <div className="block text-medium-base font-primary " />
                        {field.value ? (
                          <ImageUploadPreview
                            text="Clear"
                            onChange={() => {
                              form.setValue(field.name, '');
                            }}
                            imageUrl={field.value[0].preview}
                          />
                        ) : (
                          <Cropper
                            onOk={(file) => {
                              field.onChange(file);
                              form.setValue(field.name, [file]);
                            }}
                            customCropSize={{
                              aspect: 1 / 1,
                              circularCrop: false,
                            }}
                            baseDraggerClassname="h-[102px] bg-[rgba(0,0,0,0.02)]"
                          />
                        )}
                      </>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button
                htmlType="submit"
                className="h-[40px] w-full  mt-2"
                disabled={!uploadPicture || uploadPicture.length === 0}
                loading={!isUploadFailed && loading}
              >
                <div className="flex items-center space-x-1">
                  <span>Next</span>
                  <ChevronRight width="20" height="20" />
                </div>
              </Button>
            </form>
          </Form>
          {signatureId && (
            <Button
              htmlType="submit"
              variant="link"
              className=" w-full"
              onClick={async () => {
                await updateUser({
                  variables: {
                    data: { onboardingStatus: onboardingStatus?.COMPLETED },
                  },
                });
                navigate(`/app/${workspaceId}/onboarding/info/${signatureId}`);
                // if (workspaceId && signatureId) {
                //   navigate(
                //     `/app/${workspaceId}/signature/${signatureId}/information?showTemplate=true&&isCreate=false`,
                //   );
                // }
              }}
            >
              <u className="text-darkblue">Continue Without Headshot</u>
            </Button>
          )}

          {/* {isAIHeadshotsAllowed && (
            <div className="w-full flex justify-center mt-[20px]">
              <AiHeadshotButton className="flex justify-center bg-gradient-to-r from-[#8557E9] via-[#BC57E6] to-[#F357E3]">
                Try AI Headshot
                <Zap size={16} className="ml-[6px] fill-white-0" />
              </AiHeadshotButton>
            </div>
          )} */}
        </div>
      </div>
      <div
        className="flex items-center justify-center lg:hidden h-full min-h-screen gap-[10px] shrink-0 overflow-hidden bg-cover bg-no-repeat bg-center md:w-0 md:h-full"
        style={{ backgroundImage: `url(${SideCoverHeadshot})` }}
      >
        <OnboardingSignaturePreview signature={signature} />
      </div>
      <div className="bg-[#E2D7F8] flex items-center justify-center" />
    </div>
  );
}

export default PrivateHeadshot;
