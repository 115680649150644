/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog, DialogContent } from 'components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import { useCreateTeammate, useCreateTeammatesQueue } from 'hooks/teammate';
import { useWorkspaceId } from 'hooks/workspace';
import { useGetWorkspaceTeammateByWorkspaceId } from 'hooks/workspace-teammate';
import { LoaderCircle } from 'lucide-react';
import { toast } from 'hooks/use-toast';
import { Badge } from 'components/ui/badge';
import useGetGoogleProfileImage from '../utils/useGetGoogleProfileImage';

const SelectUsersDialog = forwardRef(({ users, onClose, token }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUserAliases, setShowUserAliases] = useState(false);
  const [showDomainAliases, setShowDomainAliases] = useState(false);
  const { workspaceId } = useWorkspaceId();
  const [createTeammate] = useCreateTeammate();
  const [searchQuery, setSearchQuery] = useState('');
  const [getWorkspaceTeammateByWorkspaceId] =
    useGetWorkspaceTeammateByWorkspaceId();
  const [createTeammatesQueue] = useCreateTeammatesQueue();
  const { fetchUserProfile } = useGetGoogleProfileImage();

  const form = useForm({
    defaultValues: {
      selectedUsers: [],
    },
  });

  const watch = form.watch();

  const { control, setValue, getValues } = form;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const fetchWorkspaceTeammates = async () => {
    await getWorkspaceTeammateByWorkspaceId({
      variables: {
        where: {
          workspaceId,
        },
      },
    });
  };

  const handleShowUserAliases = (isChecked) => {
    setShowUserAliases(isChecked);
  };

  const handleShowDomainAliases = (isChecked) => {
    setShowDomainAliases(isChecked);
  };

  const handleUserSelect = (userId) => {
    const selectedUsers = getValues('selectedUsers');
    if (selectedUsers.includes(userId)) {
      setValue(
        'selectedUsers',
        selectedUsers.filter((id) => id !== userId),
      );
    } else {
      setValue('selectedUsers', [...selectedUsers, userId]);
    }
  };

  const filteredUsers = users.flatMap((user) => {
    const userAliases = user?.aliases || [];
    const domainAliases = user?.domainAliases || [];

    const userWithAliases = [
      {
        id: user?.id,
        name: user?.name,
        phone: user?.phone,
        email: user?.email,
        profileImage: user?.profileImage || null,
        position: user?.position || '',
        department: user?.department || '',
        type: 'user',
      },
      ...(showUserAliases
        ? userAliases.map((alias, index) => ({
            id: `${user?.id}-user-alias-${index}`,
            name: `${user?.name}`,
            phone: user?.phone,
            email: alias,
            profileImage: user?.profileImage || null,
            position: user?.position || '',
            department: user?.department || '',
            type: 'user-alias',
          }))
        : []),
      ...(showDomainAliases
        ? domainAliases.map((alias, index) => ({
            id: `${user?.id}-domain-alias-${index}`,
            name: `${user?.name}`,
            phone: user?.phone,
            email: alias,
            profileImage: user?.profileImage || null,
            position: user?.position || '',
            department: user?.department || '',
            type: 'domain-alias',
          }))
        : []),
    ];

    return userWithAliases.filter(
      (entry) =>
        entry.name.toLowerCase().includes(searchQuery) ||
        entry.email.toLowerCase().includes(searchQuery),
    );
  });

  const handleSelectAll = (isChecked) => {
    if (!isChecked) {
      setValue('selectedUsers', []);
    } else {
      const filteredUserIds = filteredUsers.map((user) => user?.id);
      setValue('selectedUsers', filteredUserIds);
    }
  };

  const isAllSelected =
    filteredUsers.length > 0 &&
    filteredUsers.every((user) =>
      getValues('selectedUsers').includes(user?.id),
    );

  const handleSubmit = async (values) => {
    setLoading(true);
    const teammates = filteredUsers.filter((u) =>
      values.selectedUsers.includes(u.id),
    );

    // Prepare the selected users data for bulk creation

    const usersData = [];
    for (const user of teammates) {
      const profileImage = await fetchUserProfile(user?.email, user?.id, token);
      usersData.push({
        email: user?.email,
        name: user?.name,
        department: user?.department,
        position: user?.position,
        phone: user?.phone,
        profileImage,
      });
    }
    const variables = {
      data: {
        data: usersData,
        where: { workspaceId },
      },
    };
    try {
      const response = await createTeammatesQueue({ variables });
      const createdTeammates = response?.data?.createTeammatesQueue?.data || [];

      if (createdTeammates.length) {
        // Update the teammates list or perform any additional logic
        setIsOpen(false);
        if (typeof onClose === 'function') {
          onClose();
        }
        fetchWorkspaceTeammates();
        form.reset();
        setLoading(false);
        toast({
          description:
            'Teammates imported successfully. You can now add them to groups.',
          closeicn: 'success',
        });
      }

      return createdTeammates;
    } catch (error) {
      toast({
        description: 'Failed to import teammates. Please try again later.',
        closeicn: 'error',
      });
    }

    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent hideCloseButton className="rounded-[6px] max-w-md">
        <Form {...form}>
          <h1 className="m-0">Select Teammates</h1>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-3"
          >
            <FormField
              control={control}
              name="search"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <Input
                        placeholder="Search teammates"
                        onChange={handleSearchChange}
                        value={searchQuery}
                      />
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />

            <div className="flex items-center space-x-4 mb-2 mt-3">
              {/* Select All Checkbox */}
              <div className="flex items-center space-x-2">
                <Checkbox
                  onCheckedChange={handleSelectAll}
                  checked={isAllSelected}
                />
                <span>Select all</span>
              </div>

              {/* Show User Aliases Checkbox */}
              <div className="flex items-center space-x-2">
                <Checkbox
                  onCheckedChange={handleShowUserAliases}
                  checked={showUserAliases}
                />
                <span>Show User Aliases</span>
              </div>

              {/* Show Domain Aliases Checkbox */}
              <div className="flex items-center space-x-2">
                <Checkbox
                  onCheckedChange={handleShowDomainAliases}
                  checked={showDomainAliases}
                />
                <span>Show Domain Aliases</span>
              </div>
            </div>

            <div className="relative space-y-2 h-[200px] overflow-auto">
              {filteredUsers.map((user) => (
                <FormField
                  key={user?.id}
                  control={control}
                  name="selectedUsers"
                  render={() => (
                    <FormItem className="flex flex-col space-y-2">
                      <div className="flex items-center space-x-2">
                        <FormControl>
                          <Checkbox
                            className="mt-2"
                            checked={getValues('selectedUsers').includes(
                              user?.id,
                            )}
                            onCheckedChange={() => handleUserSelect(user?.id)}
                          />
                        </FormControl>
                        <div className="flex items-center space-x-2">
                          {user?.profileImage ? (
                            <img
                              src={user?.profileImage}
                              alt={user?.name}
                              className="w-8 h-8 rounded-full"
                            />
                          ) : (
                            <div className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full">
                              <span className="text-sm font-medium text-gray-600">
                                {user?.name
                                  .split(' ')
                                  .slice(0, 2)
                                  .map((part) => part[0])
                                  .join('')}
                              </span>
                            </div>
                          )}
                          <div>
                            <div className="font-medium flex items-center space-x-1">
                              <span>{user?.name}</span>
                              {user?.type === 'user-alias' && (
                                <Badge
                                  variant="outline"
                                  className="text-xs bg-[#DBEAFE]"
                                >
                                  User Alias
                                </Badge>
                              )}
                              {user?.type === 'domain-alias' && (
                                <Badge
                                  variant="outline"
                                  className="text-xs bg-[#DCFCE7]"
                                >
                                  Domain Alias
                                </Badge>
                              )}
                            </div>
                            <div className="text-sm text-gray-500 lowercase">
                              {user?.email}
                            </div>
                          </div>
                        </div>
                      </div>
                    </FormItem>
                  )}
                />
              ))}
            </div>

            <div className="w-full">
              <Button
                disabled={loading || watch?.selectedUsers?.length === 0}
                type="submit"
                className="w-full"
                size="sm"
              >
                Add Teammates{' '}
                {loading && (
                  <LoaderCircle className="ml-2 w-8 h-8 text-gray-1 animate-spin" />
                )}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
});

export default SelectUsersDialog;
